const headerStyle = () => ({
  header: {
    backgroundColor: "#F0F0F0",
    height: "60px",
    "@media (max-width: 720px)": {
      height: "75px",
      display: "flex",
      aligntItems: "center",
    },
  },
  ContenedorMain: {
    backgroundColor: "#4267CB",
    width: "100%",
    height: "558px",
    "@media (max-width: 720px)": {
      height: "auto",
    },
  },
  TitleWhite: {
    color: "white",
    marginTop: "2.5rem",
    fontSize: "42px",
    font: "800 34px/42px Montserrat",
    "@media (max-width: 720px)": {
      marginTop: "3.5rem",
    },
  },
  subTitle: {
    color: "white",
    marginTop: "2.5rem",
    fontSize: "42px",
    font: "800 34px/42px Montserrat",
    "@media (max-width: 720px)": {
      marginTop: "3.5rem",
      font: "800 28px Montserrat",
    },
  },
  ContainerTabs: {},
  RedIcon: {
    marginLeft: "1rem",
    cursor: "poniter",
    "@media (max-width: 720px)": {
      height: "40px",
    },
  },
  iconSize: {
    "@media (max-width: 720px)": {
      height: "40px",
    },
  },
  IconContainer: {
    marginTop: "1.5rem",
  },
  TabsContainer: {
    borderBottom: "2px solid #6184E5",
    marginTop: "2.5rem",
    "@media (max-width: 720px)": {
      marginTop: "3.5rem",
      justifyContent: "space-between",
    },
  },
  TabActive: {
    borderBottom: "5px solid white",
    textAlign: "center",
    fontSize: "27px",
    font: "400 22px/27px Montserrat",
    color: "#FFFFFF",
    cursor: "pointer",
  },
  Tabdesactive: {
    textAlign: "center",
    fontSize: "27px",
    font: "400 22px/27px Montserrat",
    color: "#6184E5",
    cursor: "pointer",
  },
  parrafo: {
    textAlign: "center",
    color: "white",
    marginBottom: "2rem",
    width: "33%",
    font: "200 19px Montserrat",
    lineHeight: "25px",
    "@media (max-width: 720px)": {
      width: "100%",
      font: "200 15px Montserrat",
    },
  },
  parrafo2: {
    textAlign: "center",
    fontSize: "19px",
    color: "white",
    font: "400 15px/19px Montserrat",
    marginBottom: "6rem",
  },
  foot: {
    borderTop: "1px solid white",
    color: "white",
    height: "40px",
    "@media (max-width: 720px)": {
      height: "60px",
      marginTop: "110px",
      display: "flex",
      aligntItems: "center",
    },
  },
  powered: {
    "@media (max-width: 720px)": {
      fontSize: "12px",
    },
  },
})

export default headerStyle
