import { useState } from 'react';
// import { useDispatch } from 'react-redux';
import axios from 'axios';
// import Session from '../util/session';
// import { useHistory } from 'react-router-dom';

function UseApi() {
  const [response, setData] = useState({});
  const [errorApi] = useState({});
  const [setSatus] = useState('');
  // const dispatch = useDispatch();
  // let history = useHistory();

  const ip = 'apis.masterbotn035.com';

  const get = async ({ endpoint, headers, store = false, action, feature }) => {
    const url = `https://${ip}${endpoint}`;
    try {
      const instance = await axios.request({
        url,
        method: 'get',
        headers,
        body: {},
      });
      const response = await instance.data;
      // if (store) {
      // 	await dispatch(
      // 		action({
      // 			payload: response.payload,
      // 			feature,
      // 		})
      // 	);
      // }
      setSatus(instance.status);
      await setData(response.payload);
    } catch (error) {
      // const errorname= error +"";
      // const Errornum = errorname.indexOf("401");
      // if(Errornum != -1){
      // 	Session.signout(() => {
      // 		history.push('/login');
      // 	});
      // }
    }
  };
  const post = async ({ endpoint, headers, data, store = false, action, feature }) => {
    const url = `https://${ip}${endpoint}`;
    try {
      const instance = await axios.request({
        url,
        method: 'POST',
        data,
        headers,
      });
      const response = await instance.data;
      if (store) {
        // await dispatch(
        // 	action({
        // 		payload: response.payload,
        // 		feature,
        // 	})
        // );
        return;
      } else {
        await setData(response);
      }
      setSatus(instance.status);
    } catch (error) {
      // const errorname= error +"";
      // const Errornum = errorname.indexOf("401");
      // if(Errornum != -1){
      // 	Session.signout(() => {
      // 		history.push('/login');
      // 	});
      // }
    }
  };
  const put = async ({ endpoint, headers, data }) => {
    const url = `https://${ip}${endpoint}`;
    try {
      const instance = await axios.request({
        url,
        method: 'PUT',
        data,
        headers,
      });
      const response = await instance.data;
      setData(response);
      setSatus(instance.status);
    } catch (error) {
      // const errorname= error +"";
      // const Errornum = errorname.indexOf("401");
      // if(Errornum != -1){
      // 	Session.signout(() => {
      // 		history.push('/login');
      // 	});
      // }
    }
  };
  const deleted = async ({ endpoint, headers, data }) => {
    const url = `https://${ip}${endpoint}`;
    try {
      const instance = await axios.request({
        url,
        method: 'DELETE',
        data,
        headers,
      });
      const response = await instance.data;
      setData(response);
      setSatus(instance.status);
    } catch (error) {
      // const errorname= error +"";
      // const Errornum = errorname.indexOf("401");
      // if(Errornum != -1){
      // 	Session.signout(() => {
      // 		history.push('/login');
      // 	});
      // }
    }
  };
  return { response, get, post, put, deleted, errorApi };
}

export default UseApi;

