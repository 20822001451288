import React from "react"
import { Link } from "gatsby"

/*Material-ui */
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

/*Local */
import LogoMasterbot from "../../images/Logo-masterbots.svg"
import Menu from "../MenuSlide"
import headerStyle from "./HeaderStyle"
// import Contador from "../ContadorRegresivo"

const useStyles = makeStyles(headerStyle)

const Header = () => {
  const classes = useStyles()
  return (
    <header className={classes.header}>
      <Grid
        xs={12}
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid xs={6} sm={5}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <img src={LogoMasterbot} alt="logoMasterbot" className={classes.image} />
          </Link>
        </Grid>
        <Grid
          xs={6}
          sm={7}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid
            xs={12}
            sm={2}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Menu />
          </Grid>
        </Grid>
      </Grid>
    </header>
  )
}

export default Header
