import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Typography, Grid } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles(theme => ({
  TextoFooter: {
    color: "white",
    "@media (max-width: 720px)": {
      fontSize: "12px",
    },
  },
  TituloDialog: {
    color: "#4267CB",
    font: "800 34px/42px Montserrat",
  },
  TextoContent: {
    color: "#385099",
    font: "400 13px/17px Montserrat",
    textAlign: "left",
  },
  CloseIconS: {
    color: "#4267CB",
    cursor: "pointer",
  },
  actions: {
    "@media (max-width: 720px)": {
      justifyContent: "center",
    },
  },
  BarnerCokkies: {
    color: "white",
    textAlign: "left",
    textTransform: "none",
    font: "400 20px/24px PT Sans",
    "@media (max-width: 720px)": {
      font: "normal 12px/15px PT Sans",
    },
  },
  ButtonPolitica: {
    color: "#6184E5",
    cursor: "pointer",
    textDecoration: "underline",
  },
}))

const StyledButtonSig = withStyles({
  root: {
    background: "linear-gradient(90deg, #CFF31B 0%, #16B1CB 100%)",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 48,
    width: "194px",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
  },
  label: {
    textTransform: "none",
    fontFamily: "Helvetica",
    fontSize: "27px",
    font: "800 22px/27px Montserrat",
  },
})(Button)

export default function ScrollDialog(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [scroll, setScroll] = React.useState("paper")

  const handleClickOpen = scrollType => () => {
    setOpen(true)
    setScroll(scrollType)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <div>
      {props.type === "footer" ? (
        <Grid>
          <Button
            style={{ whiteSpace: "pre" }}
            onClick={handleClickOpen("paper")}
          >
            <Typography className={classes.TextoFooter}>
              AVISO DE PRIVACIDAD
            </Typography>
          </Button>
        </Grid>
      ) : props.type === "Compra" ? (
        <Typography onClick={handleClickOpen("paper")}>
          Leí aviso de privacidad
        </Typography>
      ) : (
        <Grid xs={12} className={classes.BarnerCokkies}>
          Éste sitio web usa cookies, si continuas la navegación se acepta su
          uso. Puede leer <br />
          más sobre el uso de cookies en nuestra{" "}
          <spam
            className={classes.ButtonPolitica}
            onClick={handleClickOpen("paper")}
          >
            política de privacidad
          </spam>
          .
        </Grid>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Grid
            xs={12}
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
          >
            <Typography className={classes.CloseIconS} onClick={handleClose}>
              <CloseIcon />
            </Typography>
          </Grid>
          <Typography className={classes.TituloDialog}>
            Aviso de Privacidad
          </Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            className={classes.TextoContent}
          >
            AVISO DE PRIVACIDAD PARA CLIENTES En cumplimiento a la Ley Federal
            de Protección de Datos Personales en Posesión de los Particulares
            (LFPDP), su Reglamento y los LINEAMIENTOS del Aviso de privacidad,
            CORPORATIVO AXXUM, S.A. DE C.V., (en adelante “AXXUM”) con domicilio
            en calle Río Manzanares No. 308, oficina 3, Colonia Del Valle en San
            Pedro Garza García, Nuevo León, C.P. 66220, en su carácter de
            Responsable pone a su disposición el presente Aviso de Privacidad
            (en lo sucesivo referido como “Aviso”), con el fin de informarle los
            términos conforme a los cuales se tratarán los datos personales de
            usted el Titular de los datos:
            <br />
            CONSENTIMIENTO DEL TITULAR. El Titular manifiesta (i) que el
            presente Aviso le ha sido dado a conocer por Axxum ya sea de forma
            física, electrónica y/o auditiva, y (ii) haber entendido y acordado
            los términos expuestos en este Aviso, por lo que otorga su
            consentimiento respecto del tratamiento de sus datos personales de
            acuerdo a lo siguiente:
            <br />
            DATOS PERSONALES QUE RECABAMOS. Axxum puede recolectar datos
            personales del Titular mediante la entrega directa y/o personal por
            cualquier medio de contacto entre el Titular y el Responsable o sus
            Encargados.
            <br />
            Axxum recaba de acuerdo al servicio solicitado datos personales de
            identificación, datos financieros y/o patrimoniales, académicos,
            laborales, así como datos relacionados con la prestación de los
            servicios brinda, datos relacionados con la interacción del uso de
            las diversas aplicaciones y sitios Web, y datos referentes al acceso
            y/o uso de los servicios prestados.
            <br />
            FINALIDAD DE LOS DATOS PERSONALES.
            <br />
            FINALIDADES PRIMARIAS. Axxum tratará los datos personales del
            Titular con la finalidad de llevar a cabo las actividades y
            gestiones enfocadas al cumplimiento de las obligaciones originadas y
            derivadas de cualquier relación jurídica y/o comercial que
            establezca con motivo de la prestación de sus servicios y/o venta de
            productos; validar la veracidad de sus datos; corroborar su
            identidad a través del servicio de verificación que ofrece el INE;
            facturación; cobranza; tramitación y otorgamiento de crédito(s);
            atención a clientes; servicio técnico; otorgamiento de garantías;
            gestión de servicios de valor agregado y contenidos; administración
            de aplicaciones y sitios Web; entrega y envío de productos o
            servicios; contacto con el cliente, con la fuerza de ventas y
            distribuidores; y proporcionar, renovar, fidelizar, activar,
            cambiar, devolver o cancelar los servicios/productos que el Titular
            solicite. Asimismo para actividades relacionadas con el control de
            acceso y resguardo de los recursos materiales y humanos dentro de
            las instalaciones del Responsable.
            <br />
            FINALIDADES SECUNDARIAS. Asimismo, Axxum tratará datos personales
            para otras finalidades: enviar notificaciones de ofertas, avisos y/o
            mensajes promocionales; comunicaciones con fines de mercadotecnia,
            publicidad o telemarketing sobre productos y servicios nuevos o
            existentes ya sean propios o de socios comerciales; realizar
            encuestas; estadísticas; estudios de mercado con la finalidad de
            proporcionarles productos, servicios, contenidos y publicidad
            acordes a sus necesidades; registro sobre hábitos de consumo a
            través de herramientas de captura automática de datos, intereses y
            comportamiento; realizar programas de beneficios e incentivos;
            participar en redes sociales, chats y/o foros de discusión;
            participar en eventos, trivias, concursos, rifas, juegos y sorteos;
            evaluar la calidad de los servicios; y en general para cualquier
            actividad encaminada a promover, mantener, mejorar y evaluar sus
            productos y servicios.
            <br />
            El Titular puede oponerse en cualquier momento al tratamiento de sus
            datos para las finalidades secundarias a través de los medios
            puestos a su disposición para el ejercicio de sus derechos ARCO
            referidos en el numeral 7.
            <br />
            VIDEOVIGILANCIA Y FOTOGRAFIA. Toda persona que ingrese a las
            instalaciones de Axxum, podrá ser videograbada y/o fotografiada por
            nuestras cámaras de seguridad.
            <br />
            Las imágenes captadas por las cámaras del sistema de circuito
            cerrado de televisión serán utilizadas para su seguridad y de las
            personas que nos visitan, con el propósito de monitorear vía remota
            los inmuebles y, confirmar en tiempo real cualquier condición de
            riesgo para minimizarla. Asimismo, con el fin de resguardar los
            recursos materiales y humanos dentro de nuestras instalaciones. Las
            imágenes son destinadas a llevar a cabo el control de acceso a
            nuestras instalaciones corporativas. Las videograbaciones y/o
            fotografías son almacenadas por un periodo de 25 días naturales.
            <br />
            REMISIONES Y TRANSFERENCIAS DE DATOS.
            <br />
            De la REMISIÓN: Axxum requiere remitir, y/o permitir el acceso a los
            datos personales del Titular con el objeto de dar cumplimiento a sus
            obligaciones jurídicas y/o comerciales, para lo cual ha celebrado o
            celebrará diversos acuerdos legales tanto en territorio nacional
            como en el extranjero. Axxum podrá comunicar los datos personales
            recolectados a cualquier otra sociedad del mismo grupo empresarial
            que opere con los mismos procesos y políticas internas, sea que se
            encuentre en territorio nacional o en el extranjero, para su
            tratamiento de acuerdo a las finalidades descritas en este Aviso.
            <br />
            Su información personal puede remitirse, almacenarse y procesarse en
            un país distinto, lo cual se llevará a cabo de conformidad con las
            leyes de protección de datos aplicables. Axxum toma medidas para
            proteger la información personal sin importar el país donde se
            almacena o a donde se remite. Tenemos procedimientos y controles
            oportunos para procurar esta protección.
            <br />
            Para el caso puntual de la finalidad de corroborar su identidad a
            través del servicio de verificación que ofrece el INE, los datos del
            Titular serán remitidos a través de la figura de “Encargado” a dicho
            Instituto.
            <br />
            De la TRANSFERENCIA: Axxum no requiere el consentimiento del Titular
            para realizar transferencias de datos personales nacionales o
            internacionales en los casos previstos en el Artículo 37 de la LFPDP
            o en cualquier otro caso de excepción previsto por la misma u otra
            legislación aplicable.
            <br />
            PROCEDIMIENTO PARA EJERCER LOS DERECHOS ARCO Y REVOCACIÓN DEL
            CONSENTIMIENTO. El Titular tiene, en todo momento, derecho de
            ejercer el derecho de Acceso, Rectificación, Cancelación y Oposición
            (en adelante referidos como derechos ARCO) respecto de sus datos,
            así como a revocar el consentimiento que haya proporcionado,
            presentando una solicitud que debe contener la información y
            documentación siguiente:
            <br />
            1. Nombre del Titular y domicilio o correo electrónico u otro medio
            para comunicarle la respuesta a su solicitud;
            <br />
            2. Documento vigente que acredite su identidad (copia simple en
            formato impreso o formato digital, según el medio elegido de su
            credencial para votar, cédula profesional, pasaporte o VISA).
            <br />
            En caso de que el ejercicio se realice a través de un representante
            legal, se deberán adjuntar los documentos con los que se acredita la
            representación legal del Titular (copia simple en formato impreso o
            electrónico de la carta poder simple con firma autógrafa del
            Titular, el mandatario y sus correspondientes identificaciones
            oficiales vigentes – credencial de para votar, cédula profesional,
            pasaporte o VISA);
            <br />
            3. El derecho o derechos ARCO que desea ejercer, así como la
            descripción clara y precisa de los datos respecto de los que busca
            ejercer alguno de los Derechos ARCO, y;
            <br />
            4. Opcionalmente, cualquier otro elemento o documento que facilite
            la localización de los datos personales del Titular.
            <br />
            En el caso de las solicitudes de rectificación de datos personales,
            el Titular deberá también indicar las modificaciones a realizarse y
            aportar la documentación que sustente su petición.
            <br />
            Para la petición del formato, recepción y respuesta de las
            solicitudes para ejercer sus derechos ARCO, la revocación de su
            consentimiento y demás derechos previstos en la Ley ponemos a su
            disposición a la Oficina de Protección de Datos Personales, a través
            de los siguientes medios:
            <br />
            Oficina de Protección de Datos Personales
            <br />
            Presencial: Río Manzanares No. 308, oficina 3, Colonia Del Valle en
            San Pedro Garza García, Nuevo León, C.P. 66220, con un horario de
            9:00 a 16:00 horas de lunes a viernes, en días hábiles.
            <br />
            Correo electrónico: juridico@springlabs.net, con un horario de 9:00
            a 16:00 horas de lunes a viernes, en días hábiles.
            <br />
            En caso de que la información proporcionada en su solicitud sea
            errónea o insuficiente, o bien, no se acompañen los documentos de
            acreditación correspondientes, podremos solicitarle, dentro de los
            cinco días hábiles siguientes a la recepción de la solicitud, que
            aporte los elementos o documentos necesarios para dar trámite a la
            misma. El Titular contará con diez días hábiles para atender el
            requerimiento, contados a partir del día siguiente en que lo haya
            recibido. De no dar respuesta en dicho plazo, se tendrá por no
            presentada la solicitud correspondiente.
            <br />
            Axxum responderá al Titular en un plazo máximo de veinte días
            hábiles, contados desde la fecha efectiva en que se recibió la
            solicitud a efecto de que, si resulta procedente, haga efectiva la
            misma dentro de los quince días hábiles siguientes a que se
            comunique la respuesta. En todos los casos, la respuesta se dará por
            la misma vía por la que haya presentado su solicitud o en su caso
            por cualquier otro medio acordado con el Titular. Los plazos antes
            referidos podrán ser ampliados en términos de la LFPDP.
            <br />
            LIMITACIÓN DE USO Y DIVULGACIÓN DE LA INFORMACIÓN. Axxum conservará
            los datos personales del Titular únicamente durante el tiempo que
            sea necesario para procesar sus solicitudes de información,
            productos y/o servicios, así como para mantener los registros
            contables, financieros y de auditoria en términos de la normativa en
            materia de protección de datos personales, así como de la
            legislación mercantil, fiscal y administrativa vigente.
            <br />
            Los datos personales recolectados se encontrarán protegidos por
            medidas de seguridad administrativas, técnicas y físicas adecuadas
            contra el daño, pérdida, alteración, destrucción o uso, acceso o
            tratamiento no autorizados, de conformidad con lo dispuesto en la
            legislación aplicable. No obstante lo señalado, Axxum no es
            infalible a un ataque por parte de terceros no autorizados para
            obtener acceso a los sistemas físicos o lógicos de los Titulares o
            del Responsable o en los documentos electrónicos y archivos
            almacenados en sus sistemas, en tal caso, Axxum realizará las
            acciones oportunas para controlar cualquier incidente, por lo que no
            será en ningún caso responsable de los daños y perjuicios que
            pudieran derivarse de dicho acceso no autorizado.
            <br />
            El Titular o su representante legal debidamente acreditado podrán
            limitar el uso o divulgación de sus datos personales, a través de
            los mismos medios dispuestos para el ejercicio de los Derechos ARCO,
            si su solicitud resulta procedente, será registrado en el listado de
            exclusión dispuesto por Axxum para dejar de recibir información
            relativa a campañas publicitarias o de mercadotecnia.
            <br />
            En caso de que el Titular considere que Axxum ha vulnerado su
            derecho a la protección de sus datos personales, puede acudir al
            Instituto Nacional de Transparencia, Acceso a la Información y
            Protección de Datos Personales (“INAI”).
            <br />
            RECOLECCIÓN DE DATOS AL NAVEGAR EN SITIOS Y PÁGINAS WEB DE AXXUM.
            Axxum puede recabar datos a través de sus sitios Web, o mediante el
            uso de herramientas de captura automática de datos. Dichas
            herramientas le permiten recolectar la información que envía su
            navegador a dichos sitios Web, tales como el tipo de navegador que
            utiliza, el idioma de usuario, los tiempos de acceso, y la dirección
            IP de sitios Web que utilizó para acceder a los sitios del
            Responsable o sus Encargados.
            <br />
            Dentro de las herramientas de captura automática de datos utilizadas
            por Axxum en sus sitios y páginas web se encuentran las cookies, los
            Web beacons, y los enlaces en los correos electrónicos.
            <br />
            Uso de Cookies.- El correcto funcionamiento de los sitios de Axxum
            requieren de la habilitación de “cookies” en su navegador de
            Internet. Las "cookies" son pequeños archivos de datos transferidos
            por el sitio Web al disco duro de su computadora cuando navega por
            el sitio. En la mayoría de los navegadores las cookies se aceptan
            automáticamente en virtud de su configuración predeterminada, usted
            puede ajustar las preferencias de su navegador para aceptar o
            rechazar las cookies. La desactivación de las cookies puede
            inhabilitar diversas funciones de los sitios web de Axxum o que no
            se muestren correctamente. En caso de que usted prefiera eliminar
            las cookies, usted puede eliminar el archivo al final de cada sesión
            del explorador.
            <br />
            Uso de Web beacons.- También conocidos como etiquetas de Internet,
            etiquetas de píxel y clear GIFs. Axxum puede utilizar en su sitios
            web y en sus correos electrónicos con formato HTML los Web beacons,
            solos o en combinación con las cookies, para recopilar información
            sobre el uso de los sitios web y su interacción con el correo
            electrónico. El Web beacon es una imagen electrónica, llamada de un
            solo píxel (1x1) o GIF que puede reconocer información que es
            procesada en su computadora, como el caso de las cookies, la hora y
            fecha en que el sitio y sus secciones son visualizados.
            <br />
            Vínculos en los correos electrónicos de Axxum.- Los correos
            electrónicos pueden incluir vínculos que permiten a Axxum saber si
            usted activó dicho vínculo y visitó la página web de destino,
            pudiendo esta información ser incluida en su perfil. Asimismo,
            pueden incluir vínculos diseñados para dirigirlo a las secciones
            relevantes de los sitios Web, al re-direccionarlo a través de los
            servidores de Axxum el sistema de re-direccionamiento permite
            determinar la eficacia de las campañas de marketing en línea.
            <br />
            CAMBIOS AL AVISO. Axxum se reserva el derecho de actualizar
            periódicamente el presente Aviso para reflejar los cambios en sus
            prácticas de información. Es responsabilidad del Titular revisar el
            contenido del Aviso en el sitio https://springlabs.ai o
            solicitándolo al correo electrónico juridico@springlabs.net.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <StyledButtonSig onClick={handleClose}>Cerrar</StyledButtonSig>
        </DialogActions>
      </Dialog>
    </div>
  )
}
