import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import CloseIcon from "@material-ui/icons/Close"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import MenuIcon from "@material-ui/icons/Menu"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

import HomeIcon from "../images/ico_home.svg"
import PaquetesIcon from "../images/ico_paquetes.svg"
import loginIcon from "../images/ico_login.svg"
import contactoIcon from "../images/ico_contacto.svg"
import Contactform from "./FormContact"
import { Typography } from "@material-ui/core"
const drawerWidth = 343

const useStyles = makeStyles(theme => ({
  iconButtonMenu: {
    color: "#4267CB",
    fontSize: 50,
    position: "fixed",
    "@media (max-width: 720px)": {
      fontSize: 30,
    },
  },
  drawer: {
    overflowY: "hidden !important",
    width: drawerWidth,
    flexShrink: 0,
    font: "400 17px/20px Montserrat",
    "& div.MuiDrawer-paper": {
      backgroundColor: "transparent",
    },
  },
  container: {
    height: "100%",
    backgroundColor: "#191919A6",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  iconClose: { padding: "20px 10px 20px", cursor: "pointer" },
  icon: { color: "white", fontSize: 35, marginLeft: "20px" },
  list: {
    width: "343px",
    color: "white",
    textAlign: "rigth",
    filter: "alpha(opacity100)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  listItem: {
    justifyContent: "flex-end",
    textAlign: "right",
    width: "100%",
    padding: "15px 0px 15px 0px",
  },
  link: {
    width: "60%",
    textDecoration: "none",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "flex-end",
    color: "white",
    textAlign: "rigth",
    opacity: 1,
    filter: "alpha(opacity100)",
  },
  Boton: {
    display: "flex",
    alignItems: "flex-end",
    textAlign: "right",
    width: "60%",
  },
  TextoBoton: {
    marginRight: "1rem",
  },
  TextMenu: {
    color: "white",
    font: "400 17px/20px Montserrat",
  },
}))

export default function PersistentDrawerRight() {
  const classes = useStyles()
  const [openMenu, setOpenMenu] = useState(false)
  const [openContact, setOpenContact] = useState(false)
  const [routes, setRoutes] = useState([
    { name: "Home", icon: <img src={HomeIcon} alt="HOME" />, route: "/" },
    {
      name: "Paquetes",
      icon: <img src={PaquetesIcon} alt="Person" />,
      route: "/Paquetes",
    },
    {
      name: "Login",
      icon: <img src={loginIcon} alt="Login" />,
      route: "https://dashboard.masterbotn035.com/",
    },
    {
      name: "Contacto",
      icon: <img src={contactoIcon} alt="Contacto" />,
    },
  ])

  return (
    <>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Button onClick={() => setOpenMenu(true)}>
          <MenuIcon className={classes.iconButtonMenu} />
        </Button>
        <Drawer className={classes.drawer} anchor="right" open={openMenu}>
          <Grid className={classes.container} container wrap="nowrap">
            <List className={classes.list}>
              <ListItemIcon className={classes.iconClose}>
                <CloseIcon
                  onClick={() => setOpenMenu(false)}
                  style={{ color: "white", fontSize: 35 }}
                />
              </ListItemIcon>
              {routes.map(item => {
                if (item.name == "Login") {
                  return (
                    <a href={item.route} className={classes.link}>
                      <ListItem button key="close" className={classes.listItem}>
                        <Typography className={classes.TextMenu}>
                          {item.name}
                        </Typography>
                        <ListItemIcon className={classes.icon}>
                          {item.icon}
                        </ListItemIcon>
                      </ListItem>
                    </a>
                  )
                } else {
                  return (
                    <Link to={item.route} className={classes.link}>
                      <ListItem
                        button
                        key="close"
                        className={classes.listItem}
                        onClick={() => {
                          !item.route && setOpenContact(true)
                          setOpenMenu(false)
                        }}
                      >
                        <Typography className={classes.TextMenu}>
                          {item.name}
                        </Typography>
                        <ListItemIcon className={classes.icon}>
                          {item.icon}
                        </ListItemIcon>
                      </ListItem>
                    </Link>
                  )
                }
              })}
            </List>
          </Grid>
        </Drawer>
      </Grid>
      <Contactform
        TypeForm="Menu"
        open={openContact}
        setOpenContact={setOpenContact}
      ></Contactform>
    </>
  )
}
