import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { Global, css } from "@emotion/core"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import Cookies from "../components/cookiesBarner/cookiesBarner"
// import Chat from "./chat/chat"
// import Hidden from "@material-ui/core/Hidden"
import { useLocation } from "@reach/router"
import { getCurrenCompany, setCompany } from "../utils/LocalStorage"
import { DELETE_COMPANY } from "../constants/endpoints"
import UseApi from "../hooks/useApi";
// import useSeo from "../hooks/use-seo"

const Layout = props => {
  const Path = useLocation();
  const Infocompany = getCurrenCompany();
  const { post } = UseApi()

  useEffect(() => {
    if (Path.pathname !== "/store") {
      if (Infocompany.idEmpresa) {
        const query = {
          endpoint: DELETE_COMPANY,
          data: {
            idEmpresa: Infocompany.idEmpresa
          },
        }
        post(query)
        setCompany({})
      }
    }
  }, [])

  return (
    <>
      <Global
        styles={css`
          html {
          }
          body {
            font-family: "Rubik", sans-serif;
            zoom: 85% !important;
          }
          @font-face {
            font-family: "Mystery Quest";
            src: url("mystery-quest.woff2");
          }
        `}
      />
      <Helmet>
        <title>Masterbot</title>
        <meta
          name="description"
          content="proyecto para el control de la norma 035 del gobierno de la ciudad de mexico."
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        {/* <script src="https://www.paypal.com/sdk/js?client-id=AZx07DfZM06OLBHK3603oNBc0T8Y5ow8cLM9Cm1aQSblbjzHjfK2h2R1z9sV7bD3DKmXXSC5EU3YVBbh&disable-funding=card&currency=MXN"></script> */}
        <script src="https://www.paypal.com/sdk/js?client-id=AduHqb5bcMLE7kHC6YiWMsK7aKU3wbF2G10VR_3Ag64UfS081obvx5yBrxVc9ENq5H3a8xXmq4w-XKf_&disable-funding=card&currency=MXN"></script>
      </Helmet>


      <Header />

      {props.children}

      {/* <Hidden xsDown>
        <Chat />
      </Hidden> */}

      <Footer />
      {Path.pathname === "/" ? (<Cookies />) : false}
    </>
  )
}

export default ({ children, pageContext }) => {
  if (pageContext.layout === "no-navbar") {
    return (
      <Layout varNav={false} varChat={false}>
        {children}
      </Layout>
    )
  }
  return <Layout>{children}</Layout>
}

