import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import Cokkie from "../../images/cockie.svg"
import closeicon from "../../images/close.svg"
import AvisoPrivacidad from "../../components/DialogTerminos"

const useStyles = makeStyles({
  Maincontent: {
    width: "100%",
    height: "174px",
    backgroundColor: "#000000D0",
    position: "fixed",
    zIndex: "100",
    bottom: 0,
    "@media (max-width: 720px)": {},
  },
  CloseIcon: {
    cursor: "pointer",
  },
  ImageCookie:{
    "@media (max-width: 720px)": {
      height:'53px',
      width:'66px',
      marginLeft:'-2.2rem',
    },
  }
})

const CookiesBarner = props => {
  const classes = useStyles()
  const [OpenBarner, setOpenBarner] = useState(true)

  return (
    <div>
      {OpenBarner ? (
      <Grid
        xs={12}
        className={classes.Maincontent}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid xs={1} md={1}>
          <img src={Cokkie} alt="Cokkie" className={classes.ImageCookie}/>
        </Grid>
        <Grid xs={8} md={7}>
          <p>
            <AvisoPrivacidad type="BarnerCookies" />
          </p>
        </Grid>
        <Grid xs={1} md={3}>
          <img
            src={closeicon}
            alt="closeicon"
            className={classes.CloseIcon}
            onClick={() => setOpenBarner(false)}

          />
        </Grid>
      </Grid>) : false}
    </div>
  )
}

export default CookiesBarner
