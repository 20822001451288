/**
 *  @version 1.0.1
 *  @author Springlabs - javieralbor
 *  @description Componente para progreso
 * 	@process 2
 */

/* REACT MODULES */
import React from 'react';
import PropTypes from 'prop-types';

/* MATERIAL UI */
import { makeStyles } from '@material-ui/core/styles';
import Portal from '@material-ui/core/Portal';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	progress: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 99999,
		textAlign: 'center',
		backgroundColor: 'rgba(255, 255, 255, 0.75) !important',
		height: '100%',
	},
}));

const Progress = (props) => {
	const { show } = props;
	const classes = useStyles();
	const message = 'Procesando solicitud. Espere un momento...';
	const transparency = 0.85;
	const displayStyle = (show && 'flex') || 'none';

	return (
		<div>
			<Portal>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
					className={classes.progress}
					id="progressBar"
					style={{
						display: displayStyle,
						backgroundColor: `rgba(255,255,255,${transparency})`,
					}}
				>
					<Grid item>
						<CircularProgress size={50} />
						<Typography variant="h5">{message}</Typography>
					</Grid>
				</Grid>
			</Portal>
		</div>
	);
};

Progress.propTypes = {
	show: PropTypes.bool,
};

Progress.defaultProps = {
	show: false,
};

export default Progress;
