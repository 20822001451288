// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paquetes-js": () => import("./../src/pages/Paquetes.js" /* webpackChunkName: "component---src-pages-paquetes-js" */),
  "component---src-pages-store-js": () => import("./../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-terminos-condiciones-js": () => import("./../src/pages/TerminosCondiciones.js" /* webpackChunkName: "component---src-pages-terminos-condiciones-js" */)
}

