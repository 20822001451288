const isBrowser = typeof window !== `undefined`

export const setPlan = user =>
   (window.localStorage.gatsbyPlan = JSON.stringify(user))

const getPlan = () => {
  if (window.localStorage.gatsbyPlan) {
    let user = JSON.parse(window.localStorage.gatsbyPlan)
    return user ? user : {}
  }
  return {}
}

export const getCurrentPlan = () => isBrowser && getPlan()

/**Cookies */
export const setCookies = user =>
   (window.localStorage.gatsbyCookie = JSON.stringify(user))

const getCookies = () => {
  if (window.localStorage.gatsbyCookie) {
    let user = JSON.parse(window.localStorage.gatsbyCookie)
    return user ? user : {}
  }
  return {}
}

export const getCurrenCookie = () => isBrowser && getCookies()


/*Datos de la empresa*/

export const setCompany = user =>
   (window.localStorage.gatsbyCompany = JSON.stringify(user))

const getCompany = () => {
  if (window.localStorage.gatsbyCompany) {
    let user = JSON.parse(window.localStorage.gatsbyCompany)
    return user ? user : {}
  }
  return {}
}

export const getCurrenCompany = () => isBrowser && getCompany()
