const headerStyle = () => ({
  header: {
    backgroundColor: "#F0F0F0",
    height: "100px",
    display: "flex",
    aligntItems: "center",
    paddingLeft: "50px",
    "@media (max-width: 720px)": {
      height: "75px",
      paddingLeft: "0px",
    },
  },
  image: {
    height: '150px',
  },
  menu: {
  },
})

export default headerStyle
