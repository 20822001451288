import React, { useState } from "react"

/*Material-ui */
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

/*Local files */
import FaceIcon from "../../images/face.svg"
import InstaIcon from "../../images/insta.svg"
import TwitIcon from "../../images/twiit.svg"
import DialogTerminos from "../../components/DialogTerminos"
import footerStyle from "./FooterStyle"
const useStyles = makeStyles(footerStyle)

const Footer = () => {
  const classes = useStyles()
  const [Tabnumber, setTabnumber] = useState(1)
  return (
    <Grid className={classes.ContenedorMain}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
      >
        <Typography className={classes.TitleWhite}>Síguenos</Typography>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
          className={classes.IconContainer}
        >
          <a href="https://www.facebook.com/OctopyTech" target="_blank">
            <img
              src={FaceIcon}
              alt="FacebookIcon"
              className={classes.iconSize}
            />
          </a>
          <a href="https://www.instagram.com/octopytech/" target="_blank">
            <img src={InstaIcon} alt="InstaIcon" className={classes.RedIcon} />
          </a>
          <a href="https://twitter.com/octopytech" target="_blank">
            <img src={TwitIcon} alt="TwitterIcon" className={classes.RedIcon} />
          </a>
          {/* <img src={WhatsIcon} alt="Whatssap" className={classes.RedIcon} /> */}
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          xs={10}
          className={classes.TabsContainer}
        >
          <Grid
            item
            xs={2}
            className={
              Tabnumber === 1 ? classes.TabActive : classes.Tabdesactive
            }
            onClick={() => setTabnumber(1)}
          >
            CDMX
          </Grid>
          <Grid
            item
            xs={2}
            className={
              Tabnumber === 2 ? classes.TabActive : classes.Tabdesactive
            }
            onClick={() => setTabnumber(2)}
          >
            MTY
          </Grid>
        </Grid>
        {Tabnumber === 1 ? (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            xs={10}
          >
            <Typography className={classes.subTitle}>
              Ciudad de México
            </Typography>
            <p className={classes.parrafo}>
              Calle Avena 316A, Granjas México, Iztacalco, C.P. 08400, Ciudad de
              México, CDMX
              <br /> <br />
              <a href="tel:5583995500" className={classes.parrafo}>
                5583995500
              </a>{" "}
              <br /> <br />
              <a href="mailto:ventas@octopy.com" className={classes.parrafo}>
                ventas@octopy.com  
              </a>
            </p>
          </Grid>
        ) : (
          Tabnumber === 2 && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              xs={10}
            >
              <Typography className={classes.TitleWhite}>
                Monterrey, N.L.
              </Typography>
              <p className={classes.parrafo}>
                Río Manzanares #308, San Pedro García, C.P. 66220, Nuevo León,
                México. <br /> <br />
                <a href="tel:5583995500" className={classes.parrafo}>
                  5583995500
                </a>{" "}
                <br /> <br />
                <a href="mailto:ventas@octopy.com" className={classes.parrafo}>
                  ventas@octopy.com  
                </a>
              </p>
            </Grid>
          )
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        xs={12}
        className={classes.foot}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          xs={3}
        >
          <DialogTerminos type="footer" />
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          xs={7}
        >
          <h3 className={classes.powered}>POWERED BY OCTOPY - 2024</h3>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Footer
